import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";


const FooterPromise = dynamic(() => import("./FooterPromise"), {
    ssr: true,
});

const GoogleWidget = dynamic(() => import("../HomeComponent/GoogleWidget"), {
    ssr: true,
});

const FooterAccreditation = dynamic(() => import("./FooterAccreditation"), {
    ssr: true,
})


const FacebookIcon = dynamic(() => import("@mui/icons-material/Facebook"), {
    ssr: false,
});
const InstagramIcon = dynamic(() => import("@mui/icons-material/Instagram"), {
    ssr: false,
});
const LinkedInIcon = dynamic(() => import("@mui/icons-material/LinkedIn"), {
    ssr: false,
});
const TwitterIcon = dynamic(() => import("@mui/icons-material/Twitter"), {
    ssr: false,
});



const FooterCompany = dynamic(() => import("./FooterCompany"), {
    ssr: true
});
const FooterContactUs = dynamic(() => import("./FooterContactUs"), {
    ssr: true
});

const FooterInformation = dynamic(() => import("./FooterInformation"), {
    ssr: true
});

const iconSx = {
    fontSize: {
        md: '20px',
        xs: '32px'
    }
}


const FooterMobile = () => {
    const template_settings = useSelector(state => state.globalReducer.template_settings);
    const footer_links = useSelector(state => state.globalReducer.footer_links);
    const footer_socials = useSelector(state => state.globalReducer.footer_socials);

    return (
        <>
            <hr className="brand-divider m-0"/>
                {template_settings.footer_show_company_promises == 'yes' && <FooterPromise type="mobile"/>}
                {template_settings.footer_show_company_links == 'yes' && <FooterCompany links={footer_links.company_links}  type="mobile"/>}
                {template_settings.footer_show_information_links == 'yes' && <FooterInformation links={footer_links.information_links} type="mobile"/>}
            <Divider/>
            {template_settings.footer_show_contact_us == 'yes' && <FooterContactUs type="mobile"/>}

            <Grid className="pl-3 pr-3 pt-2 mt-2" justifyContent="space-evenly" container>
                {template_settings.footer_show_trustpilot == 'yes' && <Grid item>
                    <FooterAccreditation
                    type="mobile"
                />
                </Grid>}
                {
                    template_settings.footer_show_socials == 'yes' && (
                        <Grid className="pt-0 mt-3" item>
                            <div className="preview-widget d-grid" style={{display: 'grid'}}>
                                <div>
                                    <Stack direction="row" spacing={0}>
                                        {footer_socials?.facebook_url && (
                                            <a href={footer_socials.facebook_url} target="_blank"><IconButton size="large" aria-label="Facebook" color="primary">
                                                <FacebookIcon sx={iconSx} />
                                            </IconButton></a>
                                        )}
                                        {footer_socials?.instagram_url && (
                                            <a href={footer_socials.instagram_url} target="_blank"><IconButton size="large" aria-label="Instagram">
                                                <InstagramIcon sx={iconSx} />
                                            </IconButton></a>
                                        )}
                                        {footer_socials?.linkedin_url && (
                                            <a href={footer_socials.linkedin_url} target="_blank"><IconButton size="large" color="primary" aria-label="Linked In">
                                                <LinkedInIcon sx={iconSx} />
                                            </IconButton></a>
                                        )}
                                        {footer_socials?.twitter_url && (
                                            <a href={footer_socials.twitter_url} target="_blank"><IconButton size="large" color="primary" aria-label="Twitter">
                                                <TwitterIcon sx={iconSx} />
                                            </IconButton></a>
                                        )}
                                    </Stack>

                                </div>

                            </div>
                        </Grid>
                    )
                }
            </Grid>
        </>
    )
}

export default FooterMobile